export class userListDB {
    static list_user = [
        {
            avatar: "<img src='assets/images/dashboard/user5.jpg' class='imgTable'>",
            fName: "Rowan",
            lName: "Torres",
            email: "Rowan.torres@gmail.com",
            last_login: "6 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/user3.jpg' class='imgTable'>",
            fName: "Alonzo",
            lName: "Perez",
            email: "Perez.Alonzo@gmail.com",
            last_login: "2 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/user1.jpg' class='imgTable'>",
            fName: "Skylar",
            lName: "Lane",
            email: "Lane.Skylar@gmail.com",
            last_login: "1 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/boy-2.png' class='imgTable'>",
            fName: "Brody",
            lName: "Gray",
            email: "Gray.Brody@gmail.com",
            last_login: "3 Days ago",
            role: "Admin",
        },
        {
            avatar: "<img src='assets/images/dashboard/designer.jpg' class='imgTable'>",
            fName: "Colton",
            lName: "Clay",
            email: "Colton.Clay@gmail.com",
            last_login: "1 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/user.png' class='imgTable'>",
            fName: "Maxine",
            lName: "Woters",
            email: "woters.maxine@gmail.com",
            last_login: "10 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/user3.jpg' class='imgTable'>",
            fName: "Alonzo",
            lName: "Perez",
            email: "Perez.Alonzo@gmail.com",
            last_login: "2 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/user1.jpg' class='imgTable'>",
            fName: "Skylar",
            lName: "Lane",
            email: "Lane.Skylar@gmail.com",
            last_login: "1 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/boy-2.png' class='imgTable'>",
            fName: "Brody",
            lName: "Gray",
            email: "Gray.Brody@gmail.com",
            last_login: "3 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/designer.jpg' class='imgTable'>",
            fName: "Colton",
            lName: "Clay",
            email: "Colton.Clay@gmail.com",
            last_login: "1 Days ago",
            role: "Admin",
        },
        {
            avatar: "<img src='assets/images/dashboard/user.png' class='imgTable'>",
            fName: "Maxine",
            lName: "Woters",
            email: "woters.maxine@gmail.com",
            last_login: "10 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/user3.jpg' class='imgTable'>",
            fName: "Alonzo",
            lName: "Perez",
            email: "Perez.Alonzo@gmail.com",
            last_login: "2 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/user1.jpg' class='imgTable'>",
            fName: "Skylar",
            lName: "Lane",
            email: "Lane.Skylar@gmail.com",
            last_login: "1 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/boy-2.png' class='imgTable'>",
            fName: "Brody",
            lName: "Gray",
            email: "Gray.Brody@gmail.com",
            last_login: "3 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/designer.jpg' class='imgTable'>",
            fName: "Colton",
            lName: "Clay",
            email: "Colton.Clay@gmail.com",
            last_login: "1 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/user5.jpg' class='imgTable'>",
            fName: "Rowan",
            lName: "Torres",
            email: "Rowan.torres@gmail.com",
            last_login: "6 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/user3.jpg' class='imgTable'>",
            fName: "Alonzo",
            lName: "Perez",
            email: "Perez.Alonzo@gmail.com",
            last_login: "2 Days ago",
            role: "Admin",
        },
        {
            avatar: "<img src='assets/images/dashboard/user1.jpg' class='imgTable'>",
            fName: "Skylar",
            lName: "Lane",
            email: "Lane.Skylar@gmail.com",
            last_login: "1 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/boy-2.png' class='imgTable'>",
            fName: "Brody",
            lName: "Gray",
            email: "Gray.Brody@gmail.com",
            last_login: "3 Days ago",
            role: "Admin",
        },
        {
            avatar: "<img src='assets/images/dashboard/designer.jpg' class='imgTable'>",
            fName: "Colton",
            lName: "Clay",
            email: "Colton.Clay@gmail.com",
            last_login: "1 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/user.png' class='imgTable'>",
            fName: "Maxine",
            lName: "Woters",
            email: "woters.maxine@gmail.com",
            last_login: "10 Days ago",
            role: "Customer",
        },
        {
            avatar: "<img src='assets/images/dashboard/user3.jpg' class='imgTable'>",
            fName: "Alonzo",
            lName: "Perez",
            email: "Perez.Alonzo@gmail.com",
            last_login: "2 Days ago",
            role: "Customer",
        },

        
    ]
}
